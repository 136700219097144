.faq {
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  
}

.faqContent {
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
}

.sectionTitle {
  font-size: 2.5rem;
  color: #1A1F71;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  font-weight: 700;
}

.sectionTitle::after {
  content: '';
  width: 100px;
  height: 3px;
  background-color: #FF8C42;
  display: block;
  margin: 10px auto 0 auto;
}

.accordionRoot {
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.accordionRoot:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.accordionSummary {
  background-color: #1A1F71 !important;
  color: #FFFFFF;
  padding: 0 20px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.accordionSummary:hover {
  background-color: #16275E;
}

.accordionDetails {
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 0 0 8px 8px;
  font-size: 1rem;
  color: #333;
}

.question {
  font-size: 1.4rem;
  font-weight: 600;
  color: #FFFFFF;
}
