.tourDetails {
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .tourGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .tourCard {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .tourCard:hover {
    transform: translateY(-10px);
  }
  
  .tourImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .tourContent {
    padding: 20px;
  }
  
  .tourLocation {
    color: #888;
    font-size: 0.9rem;
  }
  
  .tourTitle {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #333;
  }
  
  .tourBookings {
    font-size: 0.9rem;
    color: #FF8C42;
  }
  
  .tourPrice {
    display: block;
    font-size: 1.1rem;
    margin-top: 10px;
  }
  
  .bookButton {
    background-color: #FF5722;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .bookButton:hover {
    background-color: #E64A19;
  }
  
  .badge {
    background-color: #ADD8E6; 
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  
  .seeMoreButton {
    background-color: transparent;
    color: #1A1F71;
    border: 2px solid #1A1F71;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
    transition: background-color 0.3s ease, color 0.3s ease;
    
  }
  
  .seeMoreButton:hover {
    background-color: #1A1F71;
    color: #ffffff;
  }
  
  .tourButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .tourCard {
      margin-bottom: 20px;
    }
  }
  