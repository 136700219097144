.services {
    background-color: #f9f9f9;
    padding: 40px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 2rem;
    color: #1A1F71;
    margin-bottom: 20px;
  }
  
  .serviceGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .serviceBox {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
    text-decoration: none; 
    color: inherit;
  }
  
  .serviceBox:hover {
    transform: translateY(-5px);
  }
  
  .icon {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
  
  .serviceBox p {
    font-size: 1rem;
    color: #333;
    margin: 0;
  }
  