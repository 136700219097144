.floatingIconsContainer {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 1000;
  }
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: #25d366;
    color: white;
    padding: 10px;
    border-radius: 50%;
    text-decoration: none;
    transition: transform 0.3s ease;    
  }
  
  .icon:hover {
    transform: scale(1.1);
  }
  
  .phoneIcon {
    background-color: #007bff; 
  }
  
  .whatsappIcon {
    background-color: #25d366; 
  }
  
  .facebookIcon {
    background-color: #3b5998; 
  }
  
  .blink {
    animation: blink-animation 1.5s infinite;
  }
  
  @keyframes blink-animation {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  

  @media (max-width: 768px) {
    .floatingIconsContainer {
      bottom: 100px; 
      left: 10px; 
      gap: 10px; 
    }
  
    .icon {
      padding: 10px; 
      width: 30px; 
      height: 30px; 
    }
  }
  