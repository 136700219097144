.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .errorCode {
    font-size: 8rem;
    font-weight: bold;
    color: #555;
  }
  
  .message {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .homeLink {
    font-size: 1.2rem;
    padding: 10px 20px;
    border: 2px solid #007bff;
    border-radius: 5px;
    color: #007bff;
    text-decoration: none;
  }
  