.container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .permitContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .permitItem {
    width: calc(25% - 20px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .permitImage {
    width: 100%;
    height: auto;
  }
  
  .permitItem p {
    padding: 10px;
    background-color: #f8f8f8;
    font-weight: bold;
  }
  