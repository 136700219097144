.tourDetails {
    padding: 40px 20px;
    background-color: #f8f9fa;
  }
  
  .tourGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

.tourCard {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    margin-bottom: 20px;
  }
  
  .tourCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  

  .tourImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  
  .tourContent {
    padding: 16px;
    
  }
  

  .tourTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #1a1f71;
  }

  .tourRates, .otherRates, .inclusions, .childRates, .additionalCharge {
    margin-top: 20px;
  }

  .inclusions ul, .childRates ul, .otherRates ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  .tourRate, .inclusions ul, .childRates ul, .otherRates ul {
    font-size: 1rem;
    color: #6c757d;
  }

  .inclusions ul,
.childRates ul,
.otherRates ul {
  list-style: none;  
  padding-left: 0;   
}

  h5 {
    margin-bottom: 10px;
    font-weight: 600;
    color: #333;
  }

  .mainPrice {
    font-size: 1.5rem;
    color: #E94E77;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
 

  .filters {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    max-width: 1200px;
  }
  
  .filters label {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 1rem;
    color: #1a1f71;
  }
  
  .filters select,
  .filters input {
    padding: 8px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 0.9rem;
  }
  
  .filters input[type="date"] {
    cursor: pointer;
  }
  
  .filters input[type="checkbox"] {
    margin-top: 14px;
  }
  
  @media (max-width: 768px) {
    .filters {
        /* display: flex; */
      flex-direction: column; /* Display elements in a vertical layout */
    }
  
    .filters label {
      font-size: 1rem; /* Increase font size for better readability */
      width: 100%;
    }
  
    .filters select,
    .filters input[type="date"],
    .filters input[type="checkbox"] {
      width: 100%; 
      padding: 10px;
      font-size: 1rem;
      margin-top: 5px;
    }
  
    .filters input[type="date"] {
        width: 92%; 
    }
    
    .filters button {
      margin-top: 10px;
      padding: 10px;
      font-size: 1rem;
      background-color: #1a1f71;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  
    .filters button:hover {
      background-color: #153a91;
    }


  }



  .reserveNowButton {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #FF5722;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reserveNowButton:hover {
    background-color: #e87436;
  }
  