/* Bohol.module.css */

.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .heading {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .subheading {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .paragraph {
    margin-bottom: 15px;
  }
  
  .ctaContainer {
    text-align: center;
    margin-top: 20px;
  }
  
  .ctaButton {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
  }
  