
.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
}

.topBar {
    background-color: #ffffff; 
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
}

.logo {
    max-width: 200px;
}

.contactInfo {
    font-size: 0.9rem;
    color: #333;
    display: flex;
    align-items: center;
    gap: 30px;
}

.contactInfo span {
    display: flex;
    align-items: center;
    gap: 8px;
}

.actions {
    /* display: flex;
    align-items: center; */

    text-align: center; /* Center the button */
    
}

.professionalsButton {
    background-color: #ff9800;
    color: #fff;
    border: none;
    padding: 10px 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    /* background-color: #FF8C42; 
    color: #ffffff;
    padding: 10px 16px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out; */
}

.professionalsButton:hover {
    background-color: #e68a00;
}

.legitimacyText {
    font-size: 14px;
    color: #555;
    margin-top: 8px;
    text-align: center;
  }

  
.nav {
    display: flex;
    justify-content: center;
    background-color: #1A1F71;
    padding: 15px 20px;
}

.navList {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.navItem {
    margin-right: 25px;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
}

.navItem:hover {
    text-decoration: underline;
    color: #FF8C42;
}

.mobileMenu {
    display: none;
}

.burger {
    display: none;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1001; 
}

.contactLink {
    text-decoration: none;
    color: inherit; 
    display: flex;
    align-items: center;
    gap: 5px; 
}
  

  
@media (max-width: 768px) {
    .contactLink {
        padding-left: 10px;
    }
    
    .closeButton {
        display: block;
    }

    .mobileMenu {
        background-color: #1A1F71 !important;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .mobileMenu span {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-left: 20px;
    }


    .burger {
        display: block;
        background: none;
        border: none;
        color: #fff;
        font-size: 1.5em;
        cursor: pointer;
        padding-right: 20px;
    }

    .nav {
        display: none;
    }

    .nav.open {
        display: block;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        background-color: #1A1F71 !important;
        padding: 10px 20px;
        z-index: 999;
    }

    .navList {
        flex-direction: column;
        align-items: flex-start;
    }

    .navItem {
        margin: 10px 0;
        width: 100%;
    }

    .contactInfo {
        display: none;
    }    

    .actions {
        display: none;
    }    
    
    .logo {
        max-width: 200px;
        margin: 0 auto;
        display: block;
    }
    
} 

