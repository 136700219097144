.terms {
    padding: 40px 20px;
    background-color: #f9f9f9;
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
  }
  
  .terms h2 {
    font-size: 2.5rem;
    color: #1A1F71;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  .terms h3 {
    font-size: 1.8rem;
    color: #1A1F71;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms p {
    font-size: 1rem;
    margin-bottom: 15px;
    text-align: justify;
  }
  