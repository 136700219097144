  .aboutContainer {
    margin: 0 auto; 
  }  
  
  .heroSection {
    background: url('../../assets/image/aboutus-bg.webp') no-repeat center center/cover;
    position: relative;
    padding: 4rem 0;
    text-align: center;
    color: #fff;
  }
  
  .heroSection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1;
  }

  .heroText {
    position: relative;
    z-index: 2;
  }

  .heroText h1, .heroText p {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); 
  }

  .heroText h1 {
    margin-bottom: 0.5rem;
    font-size: 3rem; 
    font-weight: 700;    
  }
  
  .heroText p {
    font-size: 1.3rem;
    font-weight: 400;
  }
  
  /* Company Section */
  .companySection,
  .servicesSection,
  .missionSection {
    padding: 2rem 0;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .companySection h2,
  .servicesSection h2,
  .missionSection h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }


h2 {
  color: #2c3e50; 
  font-weight: bold; 
  margin-bottom: 15px;
}
  
p {
  line-height: 1.7; 
  margin-bottom: 15px; 
}
  
  
  .companySection, .servicesSection, .missionSection, .sectionHolder {
    transition: transform 0.3s; 
  }

  .companySection:hover, .servicesSection:hover, .missionSection:hover, .sectionHolder:hover {
    transform: translateY(-5px);
  }

  
  .sectionContent {
    background-color: #fff; 
    border-radius: 10px; 
    padding: 30px; 
    margin: 20px 0; 
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  }

  .sectionContent p {
    font-size: 1rem;
    margin-bottom: 1.2rem;
  }
  
  /* Contact Section */
  .contactSection {
    background-color: #007bff;
    color: #fff;
    padding: 2rem 0;
  }
  
  .contactSection h2 {
    font-size: 2rem;
  }
  
  .contactSection a {
    color: #ffd700;
    text-decoration: none;
    font-weight: bold;
  }
  
  .contactSection a:hover {
    text-decoration: underline;
  }

  .sectionHolder {
    background-color: #f9f9f9; 
    padding: 40px 20px; 
    margin: 20px 0; 
    border-radius: 8px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  }
  
  .sectionHolder h2 {
    font-size: 2rem; 
    margin-bottom: 20px; 
    color: #333;
  }
  
  .sectionHolder p {
    font-size: 1.1rem; 
    line-height: 1.6; 
    color: #555; 
    margin-bottom: 15px; 
  }

  
  @media (max-width: 768px) {
    .heroText h1 {
      font-size: 2rem;
    }
    
    .sectionContent h2 {
      font-size: 1.6rem;
    }
    .sectionHolder p {
      text-align: justify;
    }
  }
  