.tourDetails {
    padding: 40px 15px;
    background-color: #f9f9f9;
    text-align: left;
  }
  
  .detailsContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .imageSection {
    flex: 1;
    margin-right: 20px;
  }
  
  .tourImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .descriptionSection {
    flex: 2;
  }
  
  .descriptionSection h2 {
    font-size: 2rem;
    color: #FF8C42;
    margin-bottom: 15px;
  }
  
  .descriptionSection p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .descriptionSection h4 {
    font-size: 1.5rem;
    color: #1A1F71;
    margin-bottom: 10px;
  }
  
  .descriptionSection h5 {
    font-size: 1.2rem;
    color: #1A1F71;
    margin-bottom: 10px;
  }
  
  .descriptionSection ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .descriptionSection ul li {
    font-size: 1rem;
    color: #555;
    padding: 8px 0;
  }
  
  .bookButton {
    background-color: #FF5722;
    color: #FFFFFF;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    display: inline-block;
    transition: background-color 0.3s ease;
  }
  
  .bookButton:hover {
    background-color: #E64A19;
  }
  
  @media (max-width: 768px) {
    .detailsContent {
      flex-direction: column;
      align-items: center;
    }
  
    .imageSection {
      margin-right: 0;
      margin-bottom: 20px; /* Add space between image and description on mobile */
    }
  
    .tourImage {
      width: 90%; /* Make the image fit better on mobile screens */
      height: auto;
      border-radius: 10px;
    }
  
    .descriptionSection {
      width: 100%; /* Make the description take up full width on mobile */
      text-align: center;
    }
  
    .descriptionSection h2 {
      font-size: 1.8rem;
    }
  
    .descriptionSection p {
      font-size: 1.1rem;
    }
  
    .descriptionSection h4, .descriptionSection h5 {
      font-size: 1.3rem;
    }
  
    .descriptionSection ul li {
      font-size: 0.9rem;
    }
  
    .bookButton {
      width: 90%;
      font-size: 1rem;
      padding: 12px;
    }
  }
 
  @media (max-width: 480px) {
    .tourDetails {
      padding: 20px 10px;
    }
  
    .tourImage {
      width: 100%;
    }
  
    .bookButton {
      font-size: 0.9rem;
      padding: 10px;
    }
  }  












  .pricingTableContainer {
    overflow-x: auto;
    margin-top: 20px;
  }
  
  .pricingTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .pricingTable th,
  .pricingTable td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .pricingTable th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  @media (max-width: 600px) {
    .pricingTable th,
    .pricingTable td {
      padding: 8px;
      font-size: 14px;
    }
  }
  