.introduction {
  padding: 60px 20px;
  background-color: #f5f5f5; 
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.introductionContent {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
}

.introductionContent h2 {
  font-size: 2.5rem;
  color: #1A1F71; 
  margin-bottom: 20px;
  font-weight: 700;
}

.introductionContent p {
  font-size: 1.2rem;
  color: #333; 
  line-height: 1.6;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .introduction {
    padding: 40px 15px;
  }

  .introductionContent h2 {
    font-size: 2rem;
  }

  .introductionContent p {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .introduction {
    padding: 30px 10px;
  }

  .introductionContent {
    padding: 15px;
  }

  .introductionContent h2 {
    font-size: 1.8rem;
  }

  .introductionContent p {
    font-size: 1rem;
  }
}
