.hero {
    position: relative;
    height: 80vh;
    width: 100%;
    background: url('../../assets/image/hero.webp') no-repeat center center;
    background-size: cover;
    color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(10, 25, 49, 0.6);
    z-index: 1;
}


.heroImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%); 
    position: absolute;
    top: 0;
    left: 0;
}
  
.heroContent {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
    padding: 0 20px;
}
  
.heroContent h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}
  
.heroContent p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.heroContent h1, .heroContent p {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); 
  }

/* .heroText {
    position: relative;
    z-index: 2;
  }

  .heroText h1, .heroText p {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); 
  }

  .heroText h1 {
    margin-bottom: 0.5rem;
    font-size: 3rem; 
    font-weight: 700;    
  }
  
  .heroText p {
    font-size: 1.3rem;
    font-weight: 400;
  } */

.ctaButton {
    background-color: #FF8C42; 
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
  
.ctaButton:hover {
    background-color: #FF7A29; 
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .hero {
        height: 60vh;
    }

    .heroContent h1 {
        font-size: 2.5rem;
    }
  
    .heroContent p {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }
  
    .ctaButton {
        font-size: 1rem;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .hero {
        height: 50vh;
    }

    .heroContent h1 {
        font-size: 2rem;
    }
  
    .heroContent p {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
  
    .ctaButton {
        font-size: 0.9rem;
        padding: 6px 12px;
    }
}
