.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    background-color: #f5f5f5; 
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.constructionContainer {
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 300px;
    height: 200px;
    margin-bottom: 20px; 
}

.sign {
    position: relative;
    width: 140px;
    height: 120px;
}

.error {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffc107;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 0 0 5px #333, 0 0 0 10px #ffc107;
}

.bar {
    width: 140px;
    height: 30px;
    background-color: #ffc107;
    border: 4px solid #333;
    position: absolute;
    left: 0;
    z-index: 1;
}

.topBar {
    top: 0;
}

.bottomBar {
    bottom: 0;
}

.bar::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #ffc107 25%, #333 25%, #333 50%, #ffc107 50%, #ffc107 75%, #333 75%, #333 100%);
    background-size: 56.57px 56.57px;
}

.stopSign {
    width: 50px;
    height: 50px;
    background-color: red;
    border: 4px solid #333;
    clip-path: polygon(25% 0%, 75% 0%, 100% 25%, 100% 75%, 75% 100%, 25% 100%, 0% 75%, 0% 25%);
    position: absolute;
    left: -60px;
    bottom: 10px;
}

.cone {
    width: 30px;
    height: 30px;
    background-color: #ffc107;
    border-left: 4px solid #333;
    border-right: 4px solid #333;
    border-bottom: 4px solid #333;
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    position: absolute;
    right: -40px;
    bottom: 0;
}

.message {
    font-size: 1.5rem;
    color: #333;
    margin-top: 20px;
    font-family: 'Pacifico', cursive; 
    letter-spacing: 1px;
    line-height: 1.6;
    max-width: 600px;
    text-align: center;
}

.message span {
    display: block;
    font-size: 2rem; 
    color: #FF8C42; 
}

/* Center the entire graphic more vertically */
.constructionContainer {
    margin-bottom: 40px; 
}

/* Mobile Responsive Adjustments */
@media (max-width: 480px) {
    .constructionContainer {
      width: 250px;
      height: 150px;
    }

    .sign {
      width: 100px;
      height: 90px;
    }

    .error {
      width: 50px;
      height: 50px;
      font-size: 14px;
    }

    .bar {
      width: 100px;
      height: 20px;
    }

    .stopSign {
      width: 40px;
      height: 40px;
      left: -50px;
    }

    .cone {
      width: 25px;
      height: 25px;
      right: -30px;
    }

    .message {
      font-size: 1.2rem;
      margin-top: 10px;
      max-width: 100%;
    }
}
