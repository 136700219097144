.reviews {
  padding: 20px;
  background-color: #f5f5f5;
  text-align: center;
}

.reviews h3 {
  font-size: 1.5rem;
  color: #1A1F71;
  margin-bottom: 10px;
}

.reviewImages {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.reviewImage {
  width: auto; 
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
}


.reviewLink {
  font-size: 1.5rem; 
  color: #FF8C42;
  text-decoration: none;
  font-weight: bold; 
  background-color: #1A1F71;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: inline-block;
  margin-top: 20px;
}

.reviewLink:hover {
  background-color: #FF7A29;
  color: #FFFFFF; 
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .reviewImages {
    flex-direction: column; 
  }

  .reviewImage {
    width: 100%; 
    height: auto; 
    margin-bottom: 10px;
  }

  .reviews h3 {
    font-size: 1.3rem; 
  }

  .reviewLink {
    font-size: 1rem; 
  }
}

@media (max-width: 480px) {
  .reviews h3 {
    font-size: 1.2rem; 
  }

  .reviewLink {
    font-size: 0.9rem; 
  }
}
