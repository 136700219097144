.footer {
  background-color: #1A1F71;
  color: #ffffff;
  padding-top: 40px;
  padding-bottom: 20px;
  font-family: Arial, sans-serif;
  width: 100%;
}

.footerContainer {
  width: 100%;
  margin: 0 auto;
}


.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 20px;
}

.contact h3 {
  margin: 0;
  font-size: 1.5rem;
}

.phone {
  font-size: 2rem;
  font-weight: bold;
}

.socialMediaIcons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.socialMediaIcons a {
  display: inline-block;
}

.socialMediaIcons img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease-in-out;
}

.socialMediaIcons img:hover {
  transform: scale(1.2);
}

.links {
  display: flex;
  gap: 20px;
}

.linkItem {
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
}

.icon {
  margin-right: 8px;
}

.middleSection {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.column h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.column ul {
  list-style: none;
  padding: 0;
}

.column ul li {
  margin-bottom: 8px;
}

.column ul li a {
  color: #ffffff;
  text-decoration: none;
}

.column ul li a:hover {
  text-decoration: underline;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #ffffff;
}

.social a {
  color: #ffffff;
  font-size: 1.5rem;
  margin-right: 10px;
}

.social a:hover {
  color: #FF8C42;
}

.bottomSection p {
  margin: 0;
  font-size: 0.9rem;
}

@media (max-width: 360px) {
  .footer {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .footerContainer {
    width: 100%; 
    margin: 0 auto;
  }

  .topSection {
    flex-direction: column; 
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 15px;
    text-align: center;
  }

  .socialMediaIcons {
    gap: 15px;
    margin-top: 10px;
  }

  .socialMediaIcons img {
    width: 25px; 
    height: 25px;
  }

  .links {
    flex-direction: column; 
    gap: 10px;
    margin-top: 15px;
  }

  .linkItem {
    font-size: 1rem; 
  }

  .middleSection {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .column {
    text-align: center;
    margin-bottom: 20px;
  }

  .column ul li a {
    font-size: 0.95rem; 
  }

  .bottomSection {
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
  }

  .social {
    margin-bottom: 15px;
  }

  .social a {
    font-size: 1.2rem;
    margin-right: 10px;
  }

  .bottomSection p {
    margin: 5px 0;
    font-size: 0.85rem; 
  }
}

@media (min-width: 768px) {
  .footerContainer {
    width: 70%; 
    margin: 0 auto; 
  }
}


.crafted-by {
  margin-top: 40px;
  font-size: 1.1em;
  color: #333;
  text-align: center;
  padding: 15px;
  background-color: #fff; 
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
}

.crafted-by:hover {
  background-color: #f0f0f0; 
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.crafted-by a {
  color: #0073e6;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s;
  background: linear-gradient(45deg, #fe0000, #ffaa01);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.crafted-by a:hover {
  text-decoration: underline;
}

