.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tabButton {
    background: none;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: #333;
    border-bottom: 2px solid transparent;
  }
  
  .tabButton:hover {
    background-color: #f0f0f0;
  }
  
  .active {
    color: #1A1F71;
    border-bottom: 2px solid #1A1F71;
  }
  